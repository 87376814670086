import React from "react";
import styles from "./MultiLang.module.scss";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

type Props = {
    className: string;
};

const languages = [
    { lang: "nl", label: "Nederlands" },
    { lang: "en", label: "English" },
];

const MultiLang = ({ className }: Props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title={t("multilang.title")}>
                <meta name="description" content={t("multilang.body")} />
            </Helmet>
            <div className={styles.buttons}>
                {languages.map(({ lang, label }) => (
                    <button
                        key={lang}
                        className={classNames(i18n.language === lang && styles.languageSelected)}
                        onClick={() => i18n.changeLanguage(lang)}
                    >
                        {label}
                    </button>
                ))}
            </div>
            <h1>{t("multilang.title")}</h1>
            <p>
                {/* Trans will be able to insert the span element into the localized string, just follow the same structure of the string.
                    Check https://react.i18next.com/latest/trans-component#how-to-get-the-correct-translation-string for more info. */}
                <Trans i18nKey="multilang.body">
                    Lorem
                    <span className={styles.goldenSnitch} data-testid="multilang-span">
                        span
                    </span>
                    Ipsum
                </Trans>
            </p>
        </div>
    );
};

MultiLang.defaultProps = {
    className: "",
};

export default MultiLang;
