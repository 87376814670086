import React from "react";
import styles from "./Bottle.module.scss";
import classNames from "classnames";

type Props = {
    className: string;
};

const Bottle = ({ className }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.bells}>
                <div className={styles.bell} />
                <div className={styles.bell} />
                <div className={styles.bell} />
            </div>
            <div className={styles.bottle}>
                <div className={styles.bubble} />
                <div className={styles.bubble} />
            </div>
        </div>
    );
};

Bottle.defaultProps = {
    className: "",
};

export default Bottle;
