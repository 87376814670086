import React, { useCallback, useMemo } from "react";
import styles from "./PlaygroundCanvas.module.scss";
import classNames from "classnames";

import createEngine, {
    DiagramModel,
    DiagramEngine,
    DiagramModelGenerics,
    RightAngleLinkFactory,
} from "@projectstorm/react-diagrams";

import { CanvasWidget } from "@projectstorm/react-canvas-core";
import { SixBlocksClusterFactory } from "../Diagrams/SixBlocksClusterWidget/SixBlocksClusterFactory";
import { SixBlocksClusterModel } from "../Diagrams/SixBlocksClusterWidget/SixBlocksClusterModel";
import { AdvancedLinkFactory } from "../Diagrams/SixBlocksArrowLink/SixBlocksArrowLink";
import { Helmet } from "react-helmet";

const CLUSTER_COLOR_KEY = "CLUSTER_COLOR";
const DROP_TYPE_KEY = "DROP_TYPE";

type Props = {
    className: string;
};

function initCanvas(): [DiagramEngine, DiagramModel<DiagramModelGenerics>] {
    //1) setup the diagram engine
    var engine = createEngine();
    engine.getLinkFactories().registerFactory(new RightAngleLinkFactory());
    engine.getLinkFactories().registerFactory(new AdvancedLinkFactory());
    engine.getNodeFactories().registerFactory(new SixBlocksClusterFactory());
    (engine.getStateMachine().getCurrentState() as any).dragCanvas.config.allowDrag = false;

    //2) setup the diagram model
    var model = new DiagramModel();

    // //3-A) create a default node
    // var node1 = getRandomNode();

    // //3-B) create another default node
    // var node2 = getRandomNode();

    // //4) add the models to the root graph
    // model.addAll(node1, node2);

    model.setGridSize(8);

    //5) load model into engine
    engine.setModel(model);

    return [engine, model];
}

function getRandomInt(max: number) {
    return Math.ceil(Math.random() * max);
}

function generateQuickGuid() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function getRandomNode() {
    const node = new SixBlocksClusterModel({ title: `Cluster ${getRandomInt(20000)}`, color: "#f98425" });
    node.setPosition(getRandomInt(600), getRandomInt(600));
    return node;
}

const PlaygroundCanvas = ({ className }: Props) => {
    const [engine, model] = useMemo(initCanvas, []);

    const addCluster = useCallback(() => {
        const node = getRandomNode();
        model.addNode(node);
        engine.repaintCanvas();
    }, [model, engine]);

    const addRandomCell = useCallback(() => {
        const nodes: SixBlocksClusterModel[] = Object.values(model.getNodes()) as SixBlocksClusterModel[];
        const node = nodes[Math.max(0, getRandomInt(nodes.length) - 1)];
        node.addCell(`${generateQuickGuid()}`);
        engine.repaintCanvas();
    }, [model, engine]);

    const dropHandler = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            switch (event.dataTransfer.getData(DROP_TYPE_KEY)) {
                case "CLUSTER":
                    const node = new SixBlocksClusterModel({
                        title: "",
                        color: event.dataTransfer.getData(CLUSTER_COLOR_KEY),
                    });
                    var point = engine.getRelativeMousePoint(event);
                    node.setPosition(point);
                    model.addNode(node);
                    break;
                case "CELL":
                    const nodes: SixBlocksClusterModel[] = Object.values(model.getNodes()) as SixBlocksClusterModel[];
                    var point = engine.getRelativeMousePoint(event);
                    const catchingNode = nodes.find((m) => m.getBoundingBox().containsPoint(point));
                    if (!catchingNode) {
                        break;
                    }
                    catchingNode.addCell(`${generateQuickGuid()}`);
                    break;
            }
            engine.repaintCanvas();
        },
        [model, engine],
    );

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Canvas prototype">
                <meta name="description" content="Proof of concept of a diagram canvas by Milvum" />
            </Helmet>
            <div className={styles.buttons}>
                <h1 className={styles.title}>Diagram Canvas - Prototype</h1>
                <div className={styles.button} onClick={addCluster}>
                    Willekeurig nieuwe Cluster
                </div>
                <div className={styles.button} onClick={addRandomCell}>
                    Willekeurig nieuwe Cel
                </div>
                <div
                    className={classNames(styles.clusterButton, styles.orange)}
                    draggable={true}
                    onDragStart={(e) => {
                        e.dataTransfer.setData(CLUSTER_COLOR_KEY, "#f98425");
                        e.dataTransfer.setData(DROP_TYPE_KEY, "CLUSTER");
                    }}
                />
                <div
                    className={classNames(styles.clusterButton, styles.blue)}
                    draggable={true}
                    onDragStart={(e) => {
                        e.dataTransfer.setData(CLUSTER_COLOR_KEY, "#28b7eb");
                        e.dataTransfer.setData(DROP_TYPE_KEY, "CLUSTER");
                    }}
                />
                <div
                    className={classNames(styles.clusterButton, styles.miniCell)}
                    draggable={true}
                    onDragStart={(e) => {
                        e.dataTransfer.setData(DROP_TYPE_KEY, "CELL");
                    }}
                />
            </div>
            <div className={styles.layer} onDrop={dropHandler} onDragOver={(e) => e.preventDefault()}>
                <CanvasWidget engine={engine} className={styles.canvas} />
            </div>
        </div>
    );
};

PlaygroundCanvas.defaultProps = {
    className: "",
};

export default PlaygroundCanvas;
