import React from "react";
import styles from "./Start.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Bottle from "../Bottle/Bottle";

type Props = {
    className: string;
};

const Start = ({ className }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <h1 className={styles.title}>Welcome to possibilities</h1>
            <Link className={styles.imageLink} to="404">
                <Bottle className={styles.bottle} />
            </Link>
            <Link className={styles.link} to="/gridofpeople">
                Grid of people
            </Link>
            <Link className={styles.link} to="/500">
                Bubbles
            </Link>
            <Link className={styles.link} to="/count">
                Count
            </Link>
            <Link className={styles.link} to="/plattegrond">
                Plattegrond
            </Link>
            <Link className={styles.link} to="/i18n">
                Internationalization
            </Link>
        </div>
    );
};

Start.defaultProps = {
    className: "",
};

export default Start;
