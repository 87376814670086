import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import Bubbles from "./components/Bubbles/Bubbles";
import CardGrid from "./components/CardGrid/CardGrid";
import Counter from "./components/Counter/Counter";
import MultiLang from "./components/MultiLang/MultiLang";
import PlaygroundCanvas from "./components/PlaygroundCanvas/PlaygroundCanvas";
import Start from "./components/Start/Start";

function App() {
    return (
        <div className={styles.app}>
            <Helmet titleTemplate="%s | Milvum Playground" defaultTitle="Welcome to possibilites">
                <meta
                    name="description"
                    content="Playground to explore the possibilities in React. A Milvum initiative."
                />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<Start className={styles.start} />} />
                    <Route path="/gridofpeople" element={<CardGrid />} />
                    <Route path="/count" element={<Counter />} />
                    <Route path="/404" element={<Bubbles />} />
                    <Route path="/plattegrond" element={<PlaygroundCanvas />} />
                    <Route path="/i18n" element={<MultiLang />} />
                    <Route path="*" element={<Bubbles duration={9000} />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
