import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./styles/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n.ts";
import { initCrashReporting } from "./CrashReporting";

initCrashReporting();

const container = document.getElementById("root");
if (!container) {
    throw Error("Container element not found in document");
}

const root = createRoot(container);
root.render(
    <Suspense fallback="">
        <App />
    </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
