import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { i18nextPlugin as TranslationCheckPlugin } from "translation-check";
import { Env } from "../utils/Env";

const shouldLog = !!Env.REACT_APP_FULL_DEV_MODE;

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(
        resourcesToBackend((language, namespace, callback) => {
            import(`../../public/locales/${language}/${namespace}.json`)
                .then(({ default: resources }) => {
                    // with dynamic import, you have to use the "default" key of the module ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#importing_defaults )
                    callback(null, resources);
                })
                .catch((error) => {
                    callback(error, null);
                });
        }),
    );

if (shouldLog) {
    i18n.use(TranslationCheckPlugin);
}

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.init({
    debug: shouldLog,
    fallbackLng: "en",
});

export default i18n;
