import * as React from "react";
import { SixBlocksClusterModel } from "./SixBlocksClusterModel";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import SixBlocksClusterWidget from "./SixBlocksClusterWidget";

export class SixBlocksClusterFactory extends AbstractReactFactory<SixBlocksClusterModel, DiagramEngine> {
    constructor() {
        super("sixblocks-cluster-node");
    }

    generateModel() {
        return new SixBlocksClusterModel();
    }

    generateReactWidget(event: any): JSX.Element {
        return <SixBlocksClusterWidget engine={this.engine as DiagramEngine} node={event.model} />;
    }
}
