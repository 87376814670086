import React, { useMemo, useState } from "react";
import styles from "./CardGrid.module.scss";
import classNames from "classnames";
import { People } from "../../data/Person";
import { Helmet } from "react-helmet";

type Props = {
    className: string;
};

const CardGrid = ({ className }: Props) => {
    const [query, setQuery] = useState("");

    const photos = useMemo(
        () => People.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())),
        [query],
    );

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Grid example">
                <meta name="description" content="Searchable team grid by Milvum" />
            </Helmet>
            <input
                className={styles.search}
                placeholder="Zoek op.."
                type="text"
                onChange={(event) => setQuery(event.target.value)}
            />
            <div className={styles.list}>
                {photos.map(({ photo, name }) => (
                    <div className={styles.item} key={name}>
                        <img className={styles.photo} src={photo} />
                        <p className={styles.name}>{name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

CardGrid.defaultProps = {
    className: "",
};

export default CardGrid;
