import React from "react";
import styles from "./SixBlocksClusterWidget.module.scss";
import classNames from "classnames";
import { SixBlocksClusterModel } from "./SixBlocksClusterModel";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { PortWidget } from "@projectstorm/react-diagrams";

type Props = {
    className: string;
    node: SixBlocksClusterModel;
    engine: DiagramEngine;
};

const SixBlocksClusterWidget = ({ className, engine, node }: Props) => {
    const ports = node.getPorts();
    const cells = node.getCells();
    return (
        <div className={classNames(styles.container, className)} style={{ background: node.color }}>
            <input type="text" defaultValue={node.getTitle()} className={styles.clusterTitle} />
            {Object.entries(cells).map(([cell, value]) => (
                <div key={cell} className={styles.cell}>
                    <PortWidget engine={engine} port={ports[`in-${cell}`]}>
                        <div className={styles.circlePort} />
                    </PortWidget>
                    <input type="text" className={styles.cellName} defaultValue={value} />
                    <PortWidget engine={engine} port={ports[`out-${cell}`]}>
                        <div className={styles.circlePort} />
                    </PortWidget>
                </div>
            ))}
        </div>
    );
};

SixBlocksClusterWidget.defaultProps = {
    className: "",
};

export default SixBlocksClusterWidget;
