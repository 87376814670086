import * as Sentry from "@sentry/browser";
import { Env } from "./utils/Env";

// Just init whatever crash reporting here.
// For inspiration check https://github.com/ripienaar/free-for-dev#crash-and-exception-handling
export function initCrashReporting() {
    if (!Env.REACT_APP_GLITCHTIP_DSN) {
        console.info("[CrashReporting] REACT_APP_GLITCHTIP_DSN not set. Crash reporting disabled.");
        return;
    }

    Sentry.init({ dsn: Env.REACT_APP_GLITCHTIP_DSN });
}
