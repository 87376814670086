import {
    Maarten,
    Elsje,
    Michiel,
    Tim,
    Randy,
    Viresh,
    Salim,
    Jelle,
    Joey,
    Lejun,
    Pedram,
    Kaj,
    Nick,
    Patrick,
    Marco,
    Remco,
    Arvind,
    Adriaan,
    Albert,
    Rob,
    Charmaine,
    Oskar,
    Chef,
    Mike,
    Henk,
} from "../assets/images/Images";

export interface Person {
    photo: string;
    name: string;
}

export const People: Person[] = [
    {
        photo: Maarten,
        name: "Maarten",
    },
    {
        photo: Elsje,
        name: "Elsje",
    },
    {
        photo: Michiel,
        name: "Michiel",
    },
    {
        photo: Tim,
        name: "Tim",
    },
    {
        photo: Randy,
        name: "Randy",
    },
    {
        photo: Viresh,
        name: "Viresh",
    },
    {
        photo: Salim,
        name: "Salim",
    },
    {
        photo: Jelle,
        name: "Jelle",
    },
    {
        photo: Joey,
        name: "Joey",
    },
    {
        photo: Lejun,
        name: "Lejun",
    },
    {
        photo: Pedram,
        name: "Pedram",
    },
    {
        photo: Kaj,
        name: "Kaj",
    },
    {
        photo: Nick,
        name: "Nick",
    },
    {
        photo: Patrick,
        name: "Patrick",
    },
    {
        photo: Marco,
        name: "Marco",
    },
    {
        photo: Remco,
        name: "Remco",
    },
    {
        photo: Arvind,
        name: "Arvind",
    },
    {
        photo: Adriaan,
        name: "Adriaan",
    },
    {
        photo: Albert,
        name: "Albert",
    },
    {
        photo: Rob,
        name: "Rob",
    },
    {
        photo: Charmaine,
        name: "Charmaine",
    },
    {
        photo: Oskar,
        name: "Oskar",
    },
    {
        photo: Chef,
        name: "Chef",
    },
    {
        photo: Mike,
        name: "Mike",
    },
    {
        photo: Henk,
        name: "Henk",
    },
];
