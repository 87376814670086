import { NodeModel } from "@projectstorm/react-diagrams";
import { BaseModelOptions, DeserializeEvent } from "@projectstorm/react-canvas-core";
import { AdvancedPortModel } from "../SixBlocksArrowLink/SixBlocksArrowLink";

export interface SixBlocksClusterModelOptions extends BaseModelOptions {
    color?: string;
    title: string;
}

export class SixBlocksClusterModel extends NodeModel {
    color: string;
    title: string;
    cells: { [name: string]: string } = {};

    constructor(options: SixBlocksClusterModelOptions = { title: "" }) {
        super({
            ...options,
            type: "sixblocks-cluster-node",
        });
        this.color = options.color || "red";
        this.title = options.title;
    }

    addCell(name: string, value = "") {
        this.addPort(new AdvancedPortModel(true, `in-${name}`));
        this.addPort(new AdvancedPortModel(false, `out-${name}`));
        this.cells[name] = value;
    }

    getCells() {
        return this.cells;
    }

    getTitle() {
        return this.title;
    }

    serialize() {
        return {
            ...super.serialize(),
            color: this.color,
        };
    }

    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
        this.color = event.data.color;
    }
}
