import React, { useCallback, useState } from "react";
import styles from "./Counter.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

type Props = {
    className: string;
};

const Counter = ({ className }: Props) => {
    const [count, setCount] = useState(0);

    const increment = useCallback(() => setCount((curr) => curr + 1), []);

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Simple counter">
                <meta name="description" content="Counter by Milvum" />
            </Helmet>
            <Link to="/" className={styles.count}>
                {count}
            </Link>
            <div className={styles.add} onClick={increment}>
                &#65291;
            </div>
        </div>
    );
};

Counter.defaultProps = {
    className: "",
};

export default Counter;
