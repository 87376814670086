import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import styles from "./Bubbles.module.scss";

interface BubbleProps {
    size: "small" | "medium" | "large";
}

const Bubble = ({ size }: BubbleProps) => {
    let sizeStyle = "small";

    switch (size) {
        case "small":
            sizeStyle = styles.small;
            break;
        case "medium":
            sizeStyle = styles.medium;
            break;
        case "large":
            sizeStyle = styles.large;
            break;
    }

    return (
        <div className={styles.bubbleHorizontal}>
            <div className={`${styles.bubble} ${sizeStyle}`} />
        </div>
    );
};

interface BubblesProps {
    duration: number;
}

const Bubbles = ({ duration }: BubblesProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = duration >= 0 ? setTimeout(() => navigate(-1), duration) : undefined;

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [duration, navigate]);

    return (
        <div className={styles.container}>
            <Helmet title="Bubbles">
                <meta name="description" content="Bubbles by Milvum" />
            </Helmet>
            <div className={styles.bubblesContainer}>
                <Bubble size="small" />
                <Bubble size="medium" />
                <Bubble size="large" />
                <Bubble size="small" />
                <Bubble size="small" />
                <Bubble size="medium" />
            </div>
        </div>
    );
};

Bubbles.defaultProps = {
    duration: -1,
};

export default Bubbles;
